function Message({status, message}){
    let className = 'message text-page-type success';
    if(status!=='OK')
    {
        className = 'message text-page-type error';
    }
    return (
        <div className={className}>{message}</div>
    )
}

export default Message;