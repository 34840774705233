function SearchForm({ searchFunction }){
    const search = () => {
        const term = document.querySelector('input[name="search"]').value;
        searchFunction(term);
    }
    const submitHandler = (e) => {
        e.preventDefault();
        search();
    }
    return (
        <form onSubmit={submitHandler}>
            <label className='text-page-type' htmlFor='search'>Search</label>
            <input name='search' type='text' placeholder='Search blog posts...' />
            <button type='button' onClick={search}>Search</button>
        </form>
    )
}

export default SearchForm;