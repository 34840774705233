import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import '../App.css';
import PageTitle from '../components/PageTitle.js';
import Content from '../components/Content.js';
import Loading from '../components/Loading.js';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import FeatureImg from '../components/FeatureImg.js';
import ScrollToTop from '../components/ScrollToTop.js';
import BlogPostAdvertList from '../components/BlogPostAdvertList';


function Post() {
  const navigate = useNavigate();
  const [otherPosts, setOtherPosts] = useState([]);
  const [post, setPost] = useState({});
  const urlParams = useParams();
  const postID = urlParams.postID;
  useEffect(() => {
    if(otherPosts.length===0)
    {
      fetch('https://data.tombeckwith.com/blog').then(response => response.json()).then(response => setOtherPosts(response));
    }
    if(post===false)
    {
      navigate('/blog')
    }
    if(Object.keys(post).length===0)
    {
      fetch('https://data.tombeckwith.com/post?postid='+postID).then(response => response.json()).then(response => setPost(response));
    }
  }, [postID, post, otherPosts, navigate])
  if(Object.keys(post).length===0)
  {
    return (
      <>
        <ScrollToTop></ScrollToTop>
        <Header></Header>
        <div className='responsive-container'>
          <div className='responsive-inner'>
            <Loading></Loading>
            <BlogPostAdvertList data={otherPosts}></BlogPostAdvertList>
          </div>
        </div>
        <Footer></Footer>
      </>
    )
  }
  return (
    <>
      <ScrollToTop></ScrollToTop>
      <Header></Header>
      <div className='responsive-container'>
        <div className='responsive-inner'>
          <PageTitle title={post.title} type='blog'></PageTitle>
          <p className='text-post-date'>{post.date}</p>
          <FeatureImg src={post.featureimg}></FeatureImg>
          <Content date={post.date} content={post.content}></Content>
          <BlogPostAdvertList data={otherPosts}></BlogPostAdvertList>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Post;
