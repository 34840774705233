import copy from '../assets/copy.svg';
import { useState } from 'react';

function Email(){
    const [copied, setCopied] = useState(false);
    const copyOnClick = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
        navigator.clipboard.writeText('tomwbeckwith@gmail.com');
    };
    return (
        <button type='button' className='email' onClick={copyOnClick}>
            tomwbeckwith@gmail.com
            <img src={copy} alt='copy'/>
            <span style={{display: copied ? 'block' : 'none'}}>Copied to clipboard</span>
        </button>
    )
}

export default Email;