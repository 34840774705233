function FeatureImg(props){
    const src = props.src;
    return (
        <div className='feature-image'>
            <div className='feature-image-bg' ><img src={src} alt='background'/></div>
            <div className='feature-image-fg'><img src={src} alt='foreground'/></div>
        </div>
    )
}

export default FeatureImg;