import { Link } from 'react-router-dom';

function BlogPost(props){
    const i = props.index;
    const date = props.data.date;
    const title = props.data.title;
    const snippet = props.data.snippet;
    const postID = props.data.id;
    const visible = props.data.visible===undefined ? true : props.data.visible;
    const buttonClass = 'button' + (i%2!==0 ? ' white' : ' ghost');
    return (
        <div className='blog-post' style={visible ? {} : {display: 'none'}}>
            <div className='blog-post-list-indicator'></div>
            <div className='blog-post-content'>
                <div className='text-page-type'>{date}</div>
                <div className='text-post-title'>{title}</div>
                <div className='text-content'>{snippet}</div>
                <div className='text-center'><Link className={buttonClass} to={'/blog/post/'+postID}>Read</Link></div>
            </div>
        </div>
    )
}

export default BlogPost;