function PageTitle(props) {
    const title = props.title;
    const type = props.type || false;
    const Component = props.component || false;
    const className = Component ? 'page-title with-component' : 'page-title';
    return (
        <>
            <div className={className}>
                <div>
                    <div className='text-page-type'>{type}</div>
                    <div className='text-page-title'>{title}</div>
                </div>
                {Component ? <Component componentFunction={props.componentFunction} searchFunction={props.searchFunction}></Component> : ''}
            </div>
        </>
    )
}

export default PageTitle;