import '../App.css';
import { useState } from 'react';
import PageTitle from '../components/PageTitle.js';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import ScrollToTop from '../components/ScrollToTop.js';
import Form from '../components/Form.js';
import MailTo from '../components/MailTo.js';
import Email from '../components/Email.js';
import Social from '../components/Social.js';



function Contact() {
  const [mailTo, setMailTo] = useState('');
  const [formVisible, setFormVisible] = useState(true);
  const formSubmit = (e) => {
    const subject = e.target.Subject.value.trim();
    const message = e.target.Message.value.trim();
    setMailTo(`mailto:tomwbeckwith@gmail.com?subject=${subject}&body=${message}`);
    setFormVisible(false);
    return false;
  }
  const fields = [
    {
      type: 'text',
      name: 'Subject',
      label: 'Subject',
      placeholder: 'Enter your subject...',
      required: true,
    },
    {
      type: 'textarea',
      name: 'Message',
      label: 'Message',
      placeholder: 'Enter your message...',
      required: true,
    },
  ];
  return (
    <>
      <ScrollToTop></ScrollToTop>
      <Header></Header>
      <div className='responsive-container'>
        <div className='responsive-inner'>
          <PageTitle title='Get in touch' type='Contact'></PageTitle>
          <p>Have questions? Want to learn more? Please feel free to get in touch.</p>
          <div className='content'>
            <div className='text-section-title'>Email</div>
            <p>I have included my email address below. For ease, clicking the button will copy my email address to your clipboard.</p>
            <Email></Email>
            <p>Alternatively, you can use the form at the bottom of this page to begin composing an email. Simply enter your message, click next, and then continue to your email app. Don't worry - you can still make changes before sending!</p>
            <Social></Social>
          </div>
          <div className='text-section-title m-0-32'>Compose email</div>
          <Form
            submit={formSubmit}
            submitLabel='Next'
            fields={fields}
            url='https://data.tombeckwith.com/contact'
            visible={formVisible}
            intro="You can use the form below to begin composing an email."></Form>
          {mailTo !== '' ? <MailTo mailto={mailTo}></MailTo> : ''}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Contact;
