import { useState } from 'react';
import Spinner from '../assets/LoadingSpinner.svg';
import Field from './Field.js';
import Message from './Message.js';


function Form(props){
    const [messages, setMessages] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const onSubmit = props.submit;
    const submitLabel = submitted ? <><img className='spinner small' src={Spinner} alt='Loading'/> Please wait</> : props.submitLabel;
    const fields = props.fields;
    const url = props.url;
    const [refresh, setRefresh] = useState(1);
    const handleResponse = (response) => {
        let currentMessages = messages;
        if(response.status>=200&&response.status<299)
        {
            currentMessages.push({'status':'OK', 'message': 'Message sent'});
            setMessages(currentMessages);
        }
        else
        {
            currentMessages.push({'status':'Error','message':'Something went wrong'});
            setMessages(currentMessages);
        }
        let newRefresh = refresh + 1;
        setRefresh(newRefresh);
        setSubmitted(false);
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
    };
    const masterOnSubmit = (e) => {
        e.preventDefault();
        const submit = onSubmit(e);
        if(typeof submit == 'boolean')
        {
            if(!submit)
            {
                return false;
            }
        }
        let formData = {};
        for(const element of e.target)
        {
            if(element.name)
            {
                formData[element.name] = element.value.trim();
            }
        }
        setSubmitted(true);
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => handleResponse(response));
    }
    return (
        <>
        {messages.map((message, i) => (<Message status={message.status} message={message.message} key={i} />))}
        <form onSubmit={masterOnSubmit} key={refresh} style={{display: props.visible ? 'block' : 'none'}}>
            <p>{props.intro}</p>
            {fields.map((field, i) => (<Field key={i} field={field}></Field>))}
            <button disabled={submitted}>{submitLabel}</button>
        </form>
        </>
    )
}

export default Form;