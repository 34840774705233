import BlogPostAdvert from './BlogPostAdvert.js';
import Loading from './Loading.js';

function BlogPostAdvertList(props){
    const data = props.data;
    if(data.length===0)
    {
        return (
            <Loading></Loading>
        )
    }
    else
    {
        return (
            <div className='blog-post-adverts'>
                <div className='text-page-type'>You may also like...</div>
                {data.map((post, i) => (<BlogPostAdvert key={i} post={post}></BlogPostAdvert>))}
            </div>
        )
    }
    
}

export default BlogPostAdvertList;