import { Link } from 'react-router-dom';

function BlogPostAdvert(props){
    const post = props.post;
    return (
        <Link to={'/blog/post/'+post.id} className='blog-post-advert'>
            <div className='blog-post-list-indicator'></div>
            <div className='blog-post-content'>
                <div className='text-content'>{post.title}</div>
                <div className='text-page-type'>{post.date}</div>
            </div>
        </Link>
    )
}

export default BlogPostAdvert;