import LogoBox from '../assets/LogoBox.svg';
import NavIcon from '../assets/NavIcon.svg';
import SearchClose from '../assets/SearchClose.svg';
import Logo from '../assets/Logo.svg';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

function Header(){
    const [menuOpen, setMenuOpen] = useState(false);
    const menuOpenClose = () => {
        setMenuOpen(!menuOpen);
    };
    const menuClass = menuOpen ? 'navigation menu-visible' : 'navigation';
    const headerStyle = {height: 81};
    const [shadow, setShadow] = useState(false);
    const checkShadow = () => {
        if(window.scrollY===0)
        {
            setShadow(false);
        }
        else
        {
            setShadow(true);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', checkShadow);
        return () => {
            window.removeEventListener('scroll', checkShadow);
        }
    }, [])
    return (
        <>
        <header className={shadow ? 'shadow': ''}>
            <Link to='/'><img src={LogoBox} alt='Tom Beckwith Logo' /></Link>
            <button className='no-style' onClick={menuOpenClose}><img src={NavIcon} alt='Menu'/></button>
            <div className={menuClass}>
                <div className='controls'>
                <Link to='/'><img src={LogoBox} alt='Tom Beckwith Logo' /></Link>
                    <button className='no-style' onClick={menuOpenClose}><img src={SearchClose} alt='Close Navigation'/></button>
                </div>
                <div className='text-page-type text-center'>Menu</div>
                <div className='navigation-content'>
                    <div className='items'>
                        <div><Link className='button white' to='/'>Home</Link></div>
                        <div><Link className='button white' to='/blog'>Blog</Link></div>
                        <div><Link className='button white' to='/contact'>Contact</Link></div>
                    </div>
                    <div className='logo'>
                        <img src={Logo} alt='Tom Beckwith Logo' />
                    </div>
                </div>
            </div>
        </header>
        <div style={headerStyle}></div>
        </>
    )
}

export default Header;