import { useState, useEffect } from 'react';
import '../App.css';
import PageTitle from '../components/PageTitle.js';
import Search from '../components/Search.js';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import BlogPost from '../components/BlogPost.js';
import ScrollToTop from '../components/ScrollToTop.js';
import Loading from '../components/Loading.js';


function Blog() {
  const [additionalComp, setAdditionalComp] = useState(false);
  const addComponent = (comp) => {
    setAdditionalComp(comp);
  }
  const filterPosts = (search) => {
    const newPosts = posts.map((post, i) => {
      if(search===""||(search!==""&&(post.title.toLowerCase().includes(search.toLowerCase())||post.snippet.toLowerCase().includes(search))))
      {
        post.visible = true;
      }
      else
      {
        post.visible = false;
      }
      return post;
    });
    setPosts(newPosts);
  }
  const [dataLoaded, setDataLoaded] = useState(false);
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch('https://data.tombeckwith.com/blog').then(response => response.json()).then(response => {
      setPosts(response);
      setDataLoaded(true);
    });
  }, [])
  return (
    <>
      <ScrollToTop></ScrollToTop>
      <Header></Header>
      <div className='responsive-container'>
        <div className='responsive-inner'>
          <PageTitle title='Blog' component={Search} searchFunction={filterPosts} componentFunction={addComponent}></PageTitle>
          {additionalComp===false ? '' : additionalComp}
          {dataLoaded ? '' : <Loading></Loading>}
          {posts.map((post, i) => (<BlogPost index={i} key={i} data={post}></BlogPost>))}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Blog;
