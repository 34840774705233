import { useState } from 'react';
import SearchIcon from '../assets/SearchIcon.svg';
import SearchClose from '../assets/SearchClose.svg';
import SearchForm from './SearchForm.js';

function Search({ componentFunction, searchFunction }){
    const [searchOpen, setSearchOpen] = useState(false);
    const [imgIcon, setImgIcon] = useState(SearchIcon);
    const onClick = () => {
        if(searchOpen)
        {
            componentFunction(
                false
            );
            setImgIcon(SearchIcon);
            searchFunction('');
        }
        else
        {
            componentFunction(
                <SearchForm searchFunction={searchFunction}></SearchForm>
            );
            setImgIcon(SearchClose);
        }
        setSearchOpen(!searchOpen);
    }
    return (
        <button className='no-style' onClick={onClick}><img role='button' src={imgIcon} alt='Search'/></button>
    )
}

export default Search;