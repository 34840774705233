import LogoBox from '../assets/LogoBox.svg';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer>
            <img src={LogoBox} alt='Tom Beckwith Logo' />
            <div><Link to='/'>Home</Link></div>
            <div><Link to='/blog'>Blog</Link></div>
            <div><Link to='/contact'>Contact</Link></div>
        </footer>
    );
}

export default Footer;