import FeatureImg from './FeatureImg.js';

function Content(props) {
    const content = props.content || [];
    const date = props.date;
    return (
        <>
            <div className='content'>
                <div className='text-page-type text-right'>{date}</div>
                {content.map((item, i) => {
                    if(item.type==='paragraph')
                    {
                        return (
                            <p key={i}>{item.value}</p>
                        )
                    }
                    else if(item.type==='image')
                    {
                        return (
                            <FeatureImg key={i} src={item.value}></FeatureImg>
                        )
                    }
                    else
                    {
                        return (
                            <div key={i}>{item.value}</div>
                        )
                    }
                })}
            </div>
        </>
    )
}

export default Content;