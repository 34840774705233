import { useState } from 'react';
import CheckCircle from '../assets/CheckCircle.svg';

function Field(props){
    const [complete, setComplete] = useState(false);
    const isComplete = (e) => {
        if(e.target.name!=='Email')
        {
            e.target.value==="" ? setComplete(false) : setComplete(true);
        }
        else
        {
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value) ? setComplete(true) : setComplete(false);
        }
    }
    const field = props.field;
    return (
        <>
            <label className='text-page-type' htmlFor={field.name}>{field.label}{complete ? <img src={CheckCircle} alt='Complete' /> : ''}</label>
            {field.type==='text' ? <input name={field.name} onKeyUp={isComplete} type={field.type} placeholder={field.placeholder} required={field.required}/> : ''}
            {field.type==='textarea' ? <textarea name={field.name} onKeyUp={isComplete} placeholder={field.placeholder} /> : ''}
        </>
    )
}

export default Field;