import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';

import Home from './pages/Home.js';
import Blog from './pages/Blog.js';
import Post from './pages/Post.js';
import Contact from './pages/Contact.js';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home></Home>,
  },
  {
    path: 'blog',
    element: <Blog></Blog>
  },
  {
    path: 'blog/post/:postID',
    element: <Post></Post>
  },
  {
    path: 'contact',
    element: <Contact></Contact>
  }
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
