import linkedin from '../assets/linkedin.svg';

function Social(){
    return (
        <div>
            <div className='text-section-title'>LinkedIn</div>
            <p>Why not connect with me on LinkedIn? The icon below will link through to my LinkedIn profile.</p>
            <div className='social'>
                <a href='https://www.linkedin.com/in/tom-beckwith-997870a6/'>
                    <img src={linkedin} alt='linked in'/>
                    <span>LinkedIn</span>
                </a>
            </div>
        </div>
    )
}

export default Social;