import { Link } from 'react-router-dom';
import '../App.css';
import Logo from '../assets/Logo.svg';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import ScrollToTop from '../components/ScrollToTop.js';


function Home() {
  return (
    <>
      <ScrollToTop></ScrollToTop>
      <Header></Header>
      <div className='fullscreen'>
        <img src={Logo} alt='Tom Beckwith Logo' />
        <div className='text-page-content space'>Designer & Developer</div>
        <Link className='button white' to='/blog'>Blog</Link>
        <Link className='button ghost' to='/contact'>Get in touch</Link>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Home;
